@import "assets/scss/style.scss";
@import "antd/dist/antd.css";

.pcoded-header {
  z-index: 1;
}

.toggle-switch {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 2;

  @media (max-width: 800px) {
    right: 150px;
  }
}

.error-msg-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 400px;
  height: fit-content;
  background: #FFFFFF;
  box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 20px 15px;

  .left {
    display: flex;
    align-items: center;
    gap: 15px;

    .messages {
      span {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 17px;
        color: #E90000;
        display: block;
      }
    }

  }

  .copy-img {
    width: 40px;
    height: 40px;
  }
}

/*remove default arrows on input type number*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.modalStyle .ant-modal-content {
  background: #3B4758;
}

.modalStyle .ant-modal-header {
  background: #3B4758;
}

.modalStyle .ant-modal-header .ant-modal-title {
  color: #fff;
}

.modalStyle .ant-modal-body .ant-input {
  background: transparent;
  color: #fff;
  border: 1px solid rgba(250, 250, 250, 0.26);
}

.modalStyle .ant-modal-close-x {
  color: #fff;
}

.bulkModal .ant-modal-content {
  background: #3B4758;
}

.bulkModal .ant-modal-header {
  background: #3B4758;
  color: #fff;
}

.bulkModal .ant-modal-header .ant-modal-title {
  color: #fff;
}

.bulkModal .ant-table-tbody {
  //background: red;
  background: #3B4758;
  color: #fff;
}

.bulkModal .ant-table-column-sorters {
  color: #fff;
}

.bulkModal .ant-table-thead > tr > th {
  background: #3B4758;
  color: #fff;
}

.bulkModal .ant-table-thead > tr:hover > th {
  background: #3B4758;
  color: #fff;
}

.bulkModal td.ant-table-column-sort {
  background: #3B4758;
}

.bulkModal .ant-table-tbody > tr:hover > td {
  background: #161b21;
}

.bulkModal .ant-modal-body .ant-input {
  background: transparent;
  color: #fff;
  border: 1px solid rgba(250, 250, 250, 0.26);
}

.bulkModal .ant-modal-close-x {
  color: #fff;
}

.bulkModal .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis, .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  color: #fff;
}


.noteModal .ant-modal-content {
  background: #3B4758;
}

.noteModal .ant-modal-body {
  background: #3B4758;
  border-radius: 0;
}

.noteModal .ant-modal-footer {
  border-top: 1px solid rgba(255, 255, 255, 0.37);
}

.noteModal .ant-modal-header .ant-modal-title {
  color: #fff;
}

.noteModal .ant-modal-body .ant-input {
  background: transparent;
  color: #fff;
  border: 1px solid rgba(250, 250, 250, 0.26);
}

.noteModal .ant-form-vertical .ant-form-item-label > label, .ant-col-24.ant-form-item-label > label, .ant-col-xl-24.ant-form-item-label > label {
  color: #fff;
  margin: 0;
}

.noteModal .ant-modal-close-x {
  color: #fff;
}

.smsModal .ant-modal-content {
  background: #3B4758;
}

.smsModal .ant-modal-body {
  background: #3B4758;
  border-radius: 0;
}

.smsModal .ant-modal-footer {
  border-top: 1px solid rgba(255, 255, 255, 0.37);
}

.smsModal .ant-modal-header .ant-modal-title {
  color: #fff;
}

.smsModal .ant-modal-body .ant-input {
  background: transparent;
  color: #fff;
  border: 1px solid rgba(250, 250, 250, 0.26);
}

.smsModal .ant-form-vertical .ant-form-item-label > label, .ant-col-24.ant-form-item-label > label, .ant-col-xl-24.ant-form-item-label > label {
  color: #fff;
  margin: 0;
}

.smsModal .ant-modal-close-x {
  color: #fff;
}

.pushNotModal .ant-modal-content {
  background: #3B4758;
}

.pushNotModal .ant-modal-body {
  background: #3B4758;
  border-radius: 0;
}

.pushNotModal .ant-modal-footer {
  border-top: 1px solid rgba(255, 255, 255, 0.37);
}

.pushNotModal .ant-modal-header .ant-modal-title {
  color: #fff;
}

.pushNotModal .ant-modal-body .ant-input {
  background: transparent;
  color: #fff;
  border: 1px solid rgba(250, 250, 250, 0.26);
}

.pushNotModal .ant-form-vertical .ant-form-item-label > label, .ant-col-24.ant-form-item-label > label, .ant-col-xl-24.ant-form-item-label > label {
  color: #fff;
  margin: 0;
}

.pushNotModal .ant-modal-close-x {
  color: #fff;
}

.deleteModal .ant-modal-content {
  background: #3B4758;
}

.deleteModal .ant-modal-body {
  background: #3B4758;
  border-radius: 0;
}

.deleteModal .ant-modal-footer {
  border-top: 1px solid rgba(255, 255, 255, 0.37);
}

.deleteModal .ant-modal-header .ant-modal-title {
  color: #fff;
}

.deleteModal .ant-modal-body .ant-input {
  background: transparent;
  color: #fff;
  border: 1px solid rgba(250, 250, 250, 0.26);
}

.deleteModal .ant-form-vertical .ant-form-item-label > label, .ant-col-24.ant-form-item-label > label, .ant-col-xl-24.ant-form-item-label > label {
  color: #fff;
  margin: 0;
}

.deleteModal .ant-modal-close-x {
  color: #fff;
}

.emailModal .ant-modal-content {
  background: #3B4758;
}

.emailModal .ant-modal-body {
  background: #3B4758;
  border-radius: 0;
}

.emailModal .ant-modal-footer {
  border-top: 1px solid rgba(255, 255, 255, 0.37);
}

.emailModal .ant-modal-header .ant-modal-title {
  color: #fff;
}

.emailModal .ant-modal-body .ant-input {
  background: transparent;
  color: #fff;
  border: 1px solid rgba(250, 250, 250, 0.26);
}

.emailModal .ant-form-vertical .ant-form-item-label > label, .ant-col-24.ant-form-item-label > label, .ant-col-xl-24.ant-form-item-label > label {
  color: #fff;
  margin: 0;
}

.emailModal .ant-modal-close-x {
  color: #fff;
}

.emailModal .rdw-editor-toolbar {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.49);
}

.emailModal .rdw-editor-main {
  color: #fff;
}

.email .ant-form-vertical .ant-form-item-label > label, .ant-col-24.ant-form-item-label > label, .ant-col-xl-24.ant-form-item-label > label {
  color: #fff;
  margin: 0;
}

.email .ant-modal-close-x {
  color: #fff;
}

.editModal .ant-modal-content {
  background: #3B4758;
}

.editModal .ant-modal-body {
  background: #3B4758;
  border-radius: 0;
}

.editModal .ant-modal-footer {
  border-top: 1px solid rgba(255, 255, 255, 0.37);
}

.editModal .ant-modal-header {
  color: #fff;
  background: #3B4758;
  border-bottom: 1px solid rgba(250, 250, 250, 0.4);
}

.editModal .ant-modal-header .ant-modal-title {
  color: #fff;
}

.editModal .ant-modal-body .ant-input {
  background: transparent;
  color: #fff;
  border: 1px solid rgba(250, 250, 250, 0.26);
}

.editModal .ant-form-vertical .ant-form-item-label > label, .ant-col-24.ant-form-item-label > label, .ant-col-xl-24.ant-form-item-label > label {
  color: #fff;
  margin: 0;
}

.editModal .ant-modal-close-x {
  color: #fff;
}

.editModal .rdw-editor-toolbar {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.49);
}

.editModal .rdw-editor-main {
  color: #fff;
}

.editModal .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #3B4758;
  color: #fafafa;
}

.approveModal .ant-modal-content {
  background: #3B4758;
}

.approveModal .ant-modal-body {
  background: #3B4758;
  border-radius: 0;
}

.approveModal .ant-modal-footer {
  border-top: 1px solid rgba(255, 255, 255, 0.37);
}

.approveModal .ant-modal-header .ant-modal-title {
  color: #fff;
}

.approveModal .ant-modal-body .ant-input {
  background: transparent;
  color: #fff;
  border: 1px solid rgba(250, 250, 250, 0.26);
}

.approveModal .ant-form-vertical .ant-form-item-label > label, .ant-col-24.ant-form-item-label > label, .ant-col-xl-24.ant-form-item-label > label {
  color: #fff;
  margin: 0;
}

.approveModal .ant-modal-close-x {
  color: #fff;
}

.archiveModal .ant-modal-content {
  background: #3B4758;
}

.archiveModal .ant-modal-body {
  background: #3B4758;
  border-radius: 0;
}

.archiveModal .ant-modal-footer {
  border-top: 1px solid rgba(255, 255, 255, 0.37);
}

.archiveModal .ant-modal-header .ant-modal-title {
  color: #fff;
}

.archiveModal .ant-modal-body .ant-input {
  background: transparent;
  color: #fff;
  border: 1px solid rgba(250, 250, 250, 0.26);
}

.archiveModal .ant-form-vertical .ant-form-item-label > label, .ant-col-24.ant-form-item-label > label, .ant-col-xl-24.ant-form-item-label > label {
  color: #fff;
  margin: 0;
}

.archiveModal .ant-modal-close-x {
  color: #fff;
}

.rejectModal .ant-modal-content {
  background: #3B4758;
}

.rejectModal .ant-modal-body {
  background: #3B4758;
  border-radius: 0;
}

.rejectModal .ant-modal-footer {
  border-top: 1px solid rgba(255, 255, 255, 0.37);
}

.rejectModal .ant-modal-header .ant-modal-title {
  color: #fff;
}

.rejectModal .ant-modal-body .ant-input {
  background: transparent;
  color: #fff;
  border: 1px solid rgba(250, 250, 250, 0.26);
}

.rejectModal .ant-form-vertical .ant-form-item-label > label, .ant-col-24.ant-form-item-label > label, .ant-col-xl-24.ant-form-item-label > label {
  color: #fff;
  margin: 0;
}

.rejectModal .ant-modal-close-x {
  color: #fff;
}

.rejectModal .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  background: #3B4758;
}

.rejectModal .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0;
  background: #3B4758;
  color: #fafafa;
}


.duplicateModal .ant-modal-content {
  background: #3B4758;
}

.duplicateModal .ant-modal-body {
  background: #3B4758;
  border-radius: 0;
  height: fit-content;
}

.duplicateModal .ant-modal-footer {
  border-top: 1px solid rgba(255, 255, 255, 0.37);
}

.duplicateModal .ant-modal-header {
  color: #fff;
  background: #3B4758;
  border-bottom: 1px solid rgba(250, 250, 250, 0.4);
}

.duplicateModal .ant-modal-header .ant-modal-title {
  color: #fff;
}

.duplicateModal .ant-modal-body .ant-input {
  background: transparent;
  color: #fff;
  border: 1px solid rgba(250, 250, 250, 0.26);
}

.duplicateModal .ant-form-vertical .ant-form-item-label > label, .ant-col-24.ant-form-item-label > label, .ant-col-xl-24.ant-form-item-label > label {
  color: #fff;
  margin: 0;
}

.duplicateModal .ant-modal-close-x {
  color: #fff;
}

.duplicateModal .rdw-editor-toolbar {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.49);
}

.duplicateModal .rdw-editor-main {
  color: #fff;
}

.duplicateModal .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #3B4758;
  color: #fafafa;
}

.editor-body {
  border: 1px solid rgba(211, 211, 211, 0.28);
}

.action-btn-wrapper {
  display: flex;
  align-items: center;

  @media (max-width: 800px) {
    flex-direction: column;
    gap: 3px;
  }
}

.duplicate-text-details {
  font-size: 16px;
  margin-top: 20px;
}

.copy-url-container {
  .copy-btn {
    margin: 10px 0;
    width: 180px;
    height: 45px;
    border-radius: 40px;
    background: rgba(211, 211, 211, 0.25);
    display: grid;
    place-items: center;
    font-weight: 800 !important;
    cursor: pointer;
  }
}

.flex-container {
  div {
    display: flex;
    align-items: flex-start;
    gap: 30px;

    @media screen and (max-width: 700px) {
      display: flex;
      flex-direction: column;
      gap: 1px;
    }
  }
}

.image-box {
  display: flex;
  width: 100%;
  margin: 0 auto;
  gap: 30px;

  .selfie-image {
    flex: 1;
    display: flex;
    justify-content: end;

    div {
      width: 50%;

      @media screen and (max-width: 700px) {
        width: 90%;
      }

      img {
        width: 100%;
      }
    }
  }

  .bvn-image {
    flex: 1;

    div {
      width: 50%;

      @media screen and (max-width: 700px) {
        width: 90%;
      }
      img {
        width: 100%;
      }
    }

  }
}

.bvn-table {
  margin: 0 auto;
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 50%;

  @media screen and (max-width: 700px) {
    width: 90%;
  }

  &-text {
    border: 1px solid black;

    div {
      height: 30px !important;
      border: 1px solid black;
      border-right: none;
      font-weight: 600;
    }
  }

  &-value {
    border: 1px solid black;

    div {
      height: 30px !important;
      border: 1px solid black;
      border-left: none;
      text-transform: uppercase;
      font-weight: bold !important;
    }
  }

}

.card .user-card {
  min-height: 300px;
  width: 100%;
  border-radius: 10px;

  .card-body {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
  }
}

.ant-input[disabled] {
  color: rgba(0, 0, 0, 0.8);

}



.nav-outside.mob-backdrop {
  &.mob-fixed .pcoded-navbar {
    z-index: 1032;
  }

  ~.pcoded-outside {
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1031;
      background: rgba(0, 0, 0, 0.25);
    }
  }
}

.pcoded-header .dropdown .notification .noti-body li {
  width: 100%;
}

.header-chat .h-list-footer .input-group {
  .btn-attach {
    padding: 0;
    width: 35px;
    height: 35px;
  }

  .btn-send {
    top: 7px;
  }
}

.main-search {
  padding: 16px 12px;
}

@media only screen and (max-width: 991px) {
  .nav-outside {
    ~ {

      .pcoded-header,
      .pcoded-main-container {
        margin-left: 0;
      }
    }

    &.mob-fixed~.pcoded-main-container {
      padding-top: 142px;
    }

  }

  .pcoded-header .navbar-nav>li:last-child {
    padding-right: 35px;
  }

}

.box {
  display: inline-block;
  padding: 10px;
  box-sizing: border-box;
}

.img-preview {
  overflow: hidden;
}

.zoom-container {
  position: relative;
  display: flex;
}

.img-preview-section-container {
  display: none;
}

.zoom-container:hover .img-preview-section-container {
  display: block;
  position: relative;
  left: 50px;
}

.zoom-container:hover .img-preview-section-container {
  left: 100%;
  position: absolute;
  background: #fff;
  border: 1px solid $theme-border;
}

.carousel-inner {
  overflow: visible;
  z-index: 1050;
}

@media only screen and (max-width: 991px) {

  .pcoded-header,
  .pcoded-main-container {
    margin-left: 0;
    width: 100%;
  }
}

.card-header .card-header-right {
  .dropdown-menu.show {
    transform: none !important;
    right: 0 !important;
    top: 100% !important;
    left: auto !important;
  }
}

header.navbar.pcoded-header.navbar-expand-lg.header-default.headerpos-fixed {
  background: $theme-background;
}

header.navbar.pcoded-header.navbar-expand-lg.header-default.headerpos-fixed .b-brand .b-title {
  color: $menu-light-text-color;
}


header.navbar.pcoded-header.navbar-expand-lg.header-default.headerpos-fixed .mobile-menu span,
header.navbar.pcoded-header.navbar-expand-lg.header-default.headerpos-fixed .mobile-menu span:after,
header.navbar.pcoded-header.navbar-expand-lg.header-default.headerpos-fixed .mobile-menu span:before {
  background-color: $menu-light-text-color;
}

header.navbar.pcoded-header.navbar-expand-lg.header-default .mobile-menu.on span {
  background-color: transparent;
}

body.datta-rtl .pcoded-navbar.theme-horizontal~.pcoded-header {
  margin-right: auto;
}

body.datta-rtl .pcoded-navbar.theme-horizontal~.pcoded-main-container {
  margin-right: auto;
}

body.datta-rtl .pcoded-navbar.theme-horizontal .pcoded-inner-navbar>li>a:after {
  left: 0;
}

body.datta-rtl .pcoded-navbar.theme-horizontal .pcoded-inner-navbar>li.active:after,
.pcoded-navbar.theme-horizontal .pcoded-inner-navbar>li.pcoded-trigger:after {
  right: 18px;
  ;
}

.theme-horizontal .sidenav-horizontal:after,
.theme-horizontal .sidenav-horizontal:before {
  content: "";
  background: $header-dark-background;
  width: 100%;
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 5;
}

.theme-horizontal.navbar-blue .sidenav-horizontal:after,
.theme-horizontal.navbar-blue .sidenav-horizontal:before {
  background: $primary-color;
}

.theme-horizontal.navbar-red .sidenav-horizontal:after,
.theme-horizontal.navbar-red .sidenav-horizontal:before {
  background: $danger-color;
}

.theme-horizontal.navbar-purple .sidenav-horizontal:after,
.theme-horizontal.navbar-purple .sidenav-horizontal:before {
  background: $purple-color;
}

.theme-horizontal.navbar-lightblue .sidenav-horizontal:after,
.theme-horizontal.navbar-lightblue .sidenav-horizontal:before {
  background: $layout-6-bg-color;
}

.theme-horizontal.navbar-dark .sidenav-horizontal:after,
.theme-horizontal.navbar-dark .sidenav-horizontal:before {
  background: $dark-color;
}

.theme-horizontal .sidenav-horizontal:before {
  left: 100%;
}

.theme-horizontal .sidenav-horizontal:after {
  right: 100%;
}

@media only screen and (max-width: 991px) {
  .pcoded-navbar {
    z-index: 1032;
  }

  body.datta-rtl {

    .pcoded-header,
    .pcoded-main-container {
      margin-right: 0;
    }
  }

  header.navbar.pcoded-header.navbar-expand-lg.header-default.headerpos-fixed {
    background: $header-dark-background;
  }

  header.navbar.pcoded-header.navbar-expand-lg.header-default.headerpos-fixed .b-brand .b-title {
    color: $header-dark-text-color;
  }


  header.navbar.pcoded-header.navbar-expand-lg.header-default.headerpos-fixed .mobile-menu span,
  header.navbar.pcoded-header.navbar-expand-lg.header-default.headerpos-fixed .mobile-menu span:after,
  header.navbar.pcoded-header.navbar-expand-lg.header-default.headerpos-fixed .mobile-menu span:before {
    background-color: $header-dark-text-color;
  }

  header.navbar.pcoded-header.navbar-expand-lg.header-default .mobile-menu.on span {
    background-color: transparent;
  }

  body.datta-dark .pcoded-header.header-dark .input-group .input-group-text,
  .pcoded-header.header-dark a,
  .pcoded-header.header-dark .btn,
  .pcoded-header.header-dark .dropdown-toggle {
    color: $dark-layout-font;
  }
}

.to-do-list .checkbox-fade .cr {
  float: left;
  border-radius: 3px;
  width: 22px;
  height: 22px;
}

.to-do-list .checkbox-fade label input[type=checkbox]:checked+.cr {
  background: #1dd5d2;
}

.user-activity .tab-content {
  padding-top: 5px;
}

.rodal-dialog {
  height: auto !important;
  bottom: auto !important;
  top: 50% !important;
  transform: translateY(-50%);

  .card {
    box-shadow: none;
    margin-bottom: 0px;

    .card-footer {
      padding-bottom: 0px;
    }
  }
}

.ant-tag {
  text-transform: capitalize
}

.carousel-inner {
  overflow: visible;
  z-index: 1050;
}

.form-control[type="file"] {
  height: auto;
  padding-left: 0.45rem;
}

.table {
  .badge {
    margin-right: 0.25rem;
  }
}

.mb-3.row {

  >.col,
  .col.d-flex {
    @media (max-width:768px) {
      margin-top: 5px;
      margin-bottom: 5px;

      &:nth-child(2) {
        flex-direction: column;
        text-align: left;

        >span {
          justify-content: flex-start !important;

          .form-control {
            max-width: 150px;
          }
        }

        .btn {
          margin-top: 8px;
          max-width: fit-content;
          margin-left: 0 !important;
        }
      }
    }
  }
}

.row.justify-content-between>.col>.d-flex.align-items-center strong {
  margin: 0 5px;
}

@media (max-width:575px) {

  .row.justify-content-between>*[class*="col-"],
  .row.justify-content-between>.col {
    flex: 100%;

    .pagination {
      justify-content: flex-start !important;

    }

    &:first-child {
      margin-bottom: 8px;
    }

    >.d-flex.align-items-center {
      input {
        width: 40px !important;
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
}

td {
  img.wid-40 {
    max-width: 40px;
  }
}

.new-cust-card {
  @media (max-width:1024px) {
    .d-inline-block {
      width: calc(100% - 55px);
    }

    .align-middle .status {
      position: relative;
      top: 0;
      left: 0;
    }
  }
}

.table th,
.table td {
  vertical-align: middle;
}

.cover-img-block {
  overflow: hidden;
  z-index: 1;
}

.user-about-block {
  position: relative;
  z-index: 5;
}

@media only screen and (max-width: 1200px) {
  .user-card .user-about-block {
    margin-top: -10px;
  }
}

.alert {
  .float-right {
    margin-left: 8px;
    float: none !important;
  }
}

@media (max-width:768px) {
  .modal-open .modal {
    padding-left: 0 !important;
  }
}

.carousel.slide {
  overflow: hidden;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  left: 18px !important;
}

.smooth-dnd-container.horizontal {
  button {
    border: none;
  }
}



.table.table-bordered.table-hover {
  th {
    select.form-control {
      min-width: 140px;
    }
  }
}

.filter-bar .navbar {
  @media (max-width:690px) {
    padding: 0.5rem 1rem 1rem;

    // #dropdown-basic{
    //   padding-left: 10px;
    // }
    .f-view {
      padding-left: 25px;
      margin-top: 8px;
    }
  }
}
