.gcp-wrapper {
  background: white;
  margin-top: 20px;
  min-height: 800px;
  border-radius: 22px;
  display: grid;
  place-items: center;
  //padding-top: 70px;
  //padding-bottom: 70px;
  //border: 3px solid red;
  width: 60%;
  position: relative;

  @media (max-width: 800px) {
    height: fit-content;
    padding: 25px 10px 40px 10px;
    width: 90%;
  }

  .back-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: 90px;
    height: 47px;
    background: #30bced33;
    border-radius: 30px;
    font-weight: 600;
    color: #435179;
    cursor: pointer;

    @media (max-width: 800px) {
      position: absolute;
      right: 15px !important;
      top: -74px;
    }

    @media (min-width: 800px) {
      position: absolute;
      left: 0;
      top: -57px;
    }

  }

  .container {
    padding-top: 30px;
    width: 100%;
    height: 100%;
    //margin: 0 auto;
    background: #EAF8FD;
    border-radius: 20px;

    .search-box {
      width: 90%;
      margin: 0 auto;
      background: rgba(48, 188, 237, 0.1);
      border-radius: 100px;
      display: flex;
      align-items: center;
      height: 38px;
      padding: 0 20px;

      .search-input {
        flex: 1;
        height: 38px;
        border-radius: 100px;
        background: transparent;
        outline: none;
        border: none;
        //padding-left: 20px;
        color: #30bced;

        &::placeholder {
          font-size: 12px;
          font-weight: 500;
          color: #30bced;
        }
      }

    }

    .select-country {
      width: 60%;
      margin: 0 auto;
      margin-top: 20px;
      height: 38px;
      background: #FFFFFF;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      text-transform: uppercase;
      color: #30BCED;

      @media (max-width: 800px) {
        width: 90%;
        margin-top: 10px;
      }
    }

    .countries-container {
      width: 50%;
      margin: 0 auto;
      margin-top: 15px;

      div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 46px;
        background: #FFFFFF;
        border-radius: 21px;
        padding: 0 20px;
        margin-bottom: 10px;
        cursor: pointer;
        transition: all ease .5s;

        &:hover {
          background: rgba(48, 188, 237, 0.1);
        }

        img {
          width: 20px;
          height: 20px;
          object-fit: cover;
        }

        span {
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 24px;
          text-align: right;
          color: #2EA8D2;
        }
      }

      @media (max-width: 800px) {
        width: 75%;
      }
    }

    .card-details {
      width: 80%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #2EA8D2;
      }

      .images {
        display: flex;
        align-items: center;
        gap: 10px;


        .country {
          width: 45px;
          height: 45px;
          border-radius: 50%;
          background: #D8F3FC;
          display: grid;
          place-items: center;

          img {
            height: 25px;
            width: 25px;
          }
        }

        .card-image {
          width: fit-content;
          height: 40px;
        }
      }

      @media (max-width: 800px) {
        margin-top: 20px;
        width: 90%;
      }
    }

    .select-amount-container {
      position: relative;
      margin: 25px auto 15px auto;
      width: 80%;
      height: 67px;
      background: rgba(48, 188, 237, 0.1);
      border-radius: 155px;

      img {
        position: absolute;
        top: 50%;
        right: 2.5%;
        transform: translate(-2.5%, -50%);
      }

      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: '';
      }

      .select-amount {
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        color: #30bced;
        font-size: 14px;
        font-weight: 600 !important;
        border-radius: 2rem;
        padding-left: 40px;
        background: rgba(48, 188, 237, 0.1);

        @media (max-width: 900px) {
          padding-left: 20px;
        }
      }

      @media (max-width: 800px) {
        width: 90%;
      }
    }

    .total-amount {
      width: 60%;
      height: 56px;
      margin: 0 auto;
      margin-bottom: 14px;
      background: rgba(61, 170, 157, 0.1);
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;

      span {
        font-weight: 600;
        font-size: 13px;
        line-height: 17px;
        color: #3DAA9D;

        &:last-child {
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          text-align: right;
          color: #3DAA9D;
        }
      }
    }
  }
}