.layout.dark {
  background: #161b21;

  .loading {
    color: #fff;
  }

  .navbar-wrapper {
    background: #1D232C;
  }

  .gcp-wrapper {
    background: #1b222b;

    .back-btn {
      background: #141c27;
      color: #fff;
    }

    .container {
      background: #1b222b;

      .select-country {
        background: #30bced23;
      }

      .countries-container {
        div {
          background: rgba(48, 188, 237, 0.1);

          &:hover {
            background: rgba(18, 43, 52, 0.42);
          }
        }
      }
    }
  }

  .pcoded-navbar.brand-default .header-logo, .pcoded-navbar[class*=navbar-].brand-default .header-logo {
    background: #1D232C !important;
  }

  .ant-table table, .ant-table-tbody {
    background: #1D232C;
    color: #FFF;
  }

  .ant-table-tbody > tr > td {
    border-bottom: 1px solid rgba(240, 240, 240, 0.18);
    -webkit-transition: background 0.3s;
    transition: background 0.3s;
  }

  td.ant-table-column-sort {
    background: rgba(3, 10, 19, 0.53);
  }

  .ant-table-tbody > tr:hover > td {
    background: #161b21;
  }

  .ant-table-thead > tr > th {
    color: #fff;
    font-weight: 600;
    text-align: left;
    font-size: 14px;
    line-height: 18px;
    background: #1D232C;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    -webkit-transition: background 0.3s ease;
    transition: background 0.3s ease;
  }

  .ant-pagination-item-ellipsis {
    color: #fff;
  }

  .ant-row-start {
    background: #1D232C;
    margin: 0 !important;
    padding: .5rem 1rem;
  }

  .ant-picker-range {
    background: #1D232C;
  }

  .ant-picker-separator {
    color: #fafafa;
  }

  .ant-picker-suffix {
    color: #fafafa;
  }

  .ant-form-vertical .ant-form-item-label > label, .ant-col-24.ant-form-item-label > label, .ant-col-xl-24.ant-form-item-label > label {
    color: #fff;
  }

  .ant-form-item .ant-mentions, .ant-form-item textarea.ant-input {
    background: transparent;
    color: #fff;
    border: 1px solid rgba(250, 250, 250, 0.26);
  }


  .ant-input {
    background: transparent;
    color: #fff;
    border: 1px solid rgba(250, 250, 250, 0.26);
  }

  .ant-list-bordered {
    border: 1px solid rgba(217, 217, 217, 0.50);
    border-radius: 2px;
  }

  .ant-list-split .ant-list-header {
    border-bottom: 1px solid rgba(240, 240, 240, 0.5);
  }

  .ant-list-split .ant-list-item {
    border-bottom: 1px solid rgba(240, 240, 240, 0.50);
  }

  .ant-list-bordered .ant-list-item > div > .ant-typography {
    color: #fafafa;
  }

  .ant-list-item-action > li > p {
    color: #ff4000;
  }

   .ant-tabs-tab > .ant-tabs-tab-btn {
   color: #fafafa;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #1890ff;
  }


  /*---------ANTD MODAL--------*/

  .ant-modal-close {
    padding-top: 30px;
    right: 2% !important;
  }


  .card .user-card {
    //box-shadow: 0 -2px 10px rgba(0, 0, 0, 1);
    color: #fafafa;
    box-shadow: 0px 3px 29px 12px rgba(0,0,0,0.1);
    -webkit-box-shadow: 0px 3px 29px 12px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 3px 29px 12px rgba(0,0,0,0.1);
  }

  .card .card-header {
    background: #1D232C;
    border-bottom: 1px solid rgba(241, 241, 241, 0.13);
    padding: 20px 25px;
    position: relative;
  }

  .card .card-body {
    background: #1D232C;
    box-shadow: 0px 4px 20px rgba(7, 6, 18, 0.1);
  }

  .theme-bg .visitor .card .card-body {
    background: purple !important;
  }

  .card-title {
    color: #FFF;
  }

  .pcoded-mtext, .feather {
    color: #fafafa;
  }

  .card-body {
    h3, h4, h5, h6 {
      color: #fff;
    }
  }

    //menu-item...
  .pcoded-navbar .pcoded-inner-navbar > li.active > a, .pcoded-navbar .pcoded-inner-navbar > li.pcoded-trigger > a {
    color: #fff;
    background: transparent;
  }

  .pcoded-navbar a {
    color: #30bced;

    &:hover {
      color: #30bced;
    }
  }

  .pcoded-navbar {
    background: #1D232C;
  }

  .pcoded-navbar .pcoded-inner-navbar {
    padding-bottom: 30px;
  }

  .pcoded-navbar .pcoded-inner-navbar li > a.active, .pcoded-navbar .pcoded-inner-navbar li > a:focus, .pcoded-navbar .pcoded-inner-navbar li > a:hover {
    color: #30bced;
  }

  .flex-container > h5 {
    color: #fff;
  }

  .rdw-editor-toolbar {
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.44);
  }

  .rdw-editor-main {
    color: #FFF;
  }

  .ant-form-item-label > label {
    color: #fafafa;
  }

   .ant-input-affix-wrapper {
    background-color: transparent;
  }

  .ant-input-affix-wrapper > input.ant-input {
    border: none;
  }

  .ant-input-prefix {
    color: #fafafa;
  }

  .ant-list-header, .ant-list-header > div > span {
    color: #fafafa;
  }

  .ant-list-item-meta-description {
    color: #fafafa;
  }

  .category-loading {
    color: #fafafa;
  }

  .ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active) {
    color: #fafafa;
  }

  .anticon {
    color: #fafafa;
  }

  .ant-switch-checked .ant-switch-loading-icon {
    color: #1890ff;
  }

  .ant-select-selector {
    background: transparent;
    color: #fff;
    border: 1px solid rgba(250, 250, 250, 0.26);
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background: transparent;
    color: #fff;
  }

  .ant-select-dropdown {
    background-color: blue !important;
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    //background: transparent !important;
  }
  .ant-select-dropdown-hidden {
    background: black !important;
  }

  .ant-picker-input > input {
    color: #fff !important;
  }


}